<template>
  <div class="view content-padding contract-content">
    <div class="row">
      <div class="col-lg-9 col-12">
        <TextEditorCard
          v-if="stateSingleData._id"
          :title="labels.contract_text"
          :value="stateSingleData.text"
          @changed="inputChanged($event.value)"
          :update-key="updateKey"
          :class="{ 'content-disabled': singleData.signed.length }"
        />
      </div>
      <div class="col-lg-3 col-12">
        <Button
          class="publish-button"
          :label="activeContract ? labels.deactivate : labels.activate"
          variant="btn-purple"
          :styles="{ padding: '8px 15px', fontWeight: 700 }"
          @click="deactivate"
        />
        <BaseCard>
          <FormulateInput
            v-if="dateOfEffect.length"
            type="date"
            name="dateOfEffect"
            :label="labels.date_of_effect"
            validation="required"
            :validation-messages="{
              required: labels.specify_date_of_effect_is_required,
            }"
            v-model="dateOfEffect"
          />
        </BaseCard>
        <LabelButtonCard class="settings__cards" :list="labelButtonList" />
      </div>
      <div class="col-12">
        <ArchiveCard
          :labels="labels"
          :title="createContractData().title"
          @archive="archiveContract"
          :type="labels.contract"
        />
      </div>
    </div>
  </div>
</template>

<script>
const moment = require("moment");

import TextEditorCard from "@/components/Input/TextEditorCard";
import LabelButtonCard from "@/components/Cards/LabelButtonCard";
import BaseCard from "@/components/Cards/BaseCard";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import ArchiveCard from "@/components/Cards/ArchiveCard";
import Button from "@/components/Buttons/Button";

export default {
  name: "ContractContent",
  components: {
    TextEditorCard,
    LabelButtonCard,
    BaseCard,
    ArchiveCard,
    Button
  },
  data() {
    return {
      value: "",
      dateOfEffect: "",
      updateKey: Date.now(),
      activeContract: true
    };
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapMutations("contract", ["updateLocalContractData"]),
    ...mapActions("contract", {
      updateContract: "update",
      archiveContractAction: "archive",
      toggleActive: "toggleActive",
      getSingle: "getSingle"
    }),
    ...mapGetters("contract", {
      createContractData: "createContractData",
    }),
    saveContractContent() {
      this.updateContract({
        id: this.stateSingleData._id,
        payload: this.stateSingleData,
      })
        .then((res) => {
          if (res.success) {
            this.addNotification({
              variant: "success",
              msg: ["saved"],
              labels: this.labels,
              dismissSecs: 5,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    deactivate(){
      this.toggleActive({id: this.$route.params.id}).then(res=>{
        if(res.success) this.activeContract = !this.activeContract
      }).catch(e=>{
        console.log(e)
      })
    },
    inputChanged(value) {
      let contractData = { ...this.stateSingleData };

      contractData.text = value;

      this.updateLocalContractData(contractData);
    },
    formatDate(isoDate) {
      const date = new Date(isoDate);
      const year = date.getFullYear();

      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }

      if (month < 10) {
        month = "0" + month;
      }

      return `${dt}.${month}.${year}`;
    },
    setEffectDateLocally(updatedData) {
      const isoDateTime = updatedData.dateOfEffect;
      const convertedDateTime = moment(isoDateTime)
        .utc()
        .format("YYYY-MM-DD");
      this.dateOfEffect = convertedDateTime;
    },
    archiveContract() {
      this.archiveContractAction({ id: this.$route.params.id }).then((res) => {
        if (res.success) {
          this.$router.push({ name: "contracts" });
        } else {
          console.log(res);
        }
      });
    },
  },
  computed: {
    ...mapState("contract", {
      singleData: "singleData",
      stateSingleData: "stateSingleData",
    }),
    ...mapGetters("helper", ["labels"]),
    labelButtonList() {
      if (!this.singleData?.lastChanged || !this.singleData?.created) return [];

      return [
        {
          labels: [
            this.labels.last_change,
            this.formatDate(this.singleData.lastChanged.date),
          ],
        },
        {
          labels: [
            this.labels.created,
            this.formatDate(this.singleData.created.date),
          ],
        },
        {
          labels: [
            this.labels.last_change_by,
            this.singleData.lastChanged.author.username,
          ],
        },
        {
          labels: [
            this.labels.created_by,
            this.singleData.created.author.username,
          ],
        },
      ];
    },
  },
  mounted() {
    if (!this.dateOfEffect?.length && this.stateSingleData?.dateOfEffect?.length) {
      this.setEffectDateLocally(this.stateSingleData);
      this.updateKey = Date.now();
    }
    this.getSingle({id: this.$route.params.id}).then(res=>{this.activeContract =res.data.active}).catch(e=>console.log(e))
  },
  watch: {
    stateSingleData(updatedData, oldData) {
      if (!Object.keys(oldData).length) {
        this.setEffectDateLocally(updatedData);
        this.updateKey = Date.now();
      }
    },
    dateOfEffect(newDateTime) {
      let contract = { ...this.stateSingleData };

      const dateObj = new Date(newDateTime).setUTCHours(0,0,0,0);

      contract.dateOfEffect = moment(dateObj).toISOString();

      this.updateLocalContractData(contract);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .settings__cards {
  padding: 0;

  &.label-button-card {
    padding: 0;
    margin-top: 30px;
  }
}
.content-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.publish-button{
  margin-left: auto;
  margin-bottom: 25px;
}
</style>
