<template>
	<BaseCard class="text-editor-card" :padding="baseCardPadding">
		<div class="title" v-if="title">{{ title }}</div>
		<FormulateInput
			type="text-editor"
			:title="title"
			:value="value"
			@changed="textEditorChanged"
            v-bind:key="updateKey"
			:error-behavior="errorBehavior"
			:validation="validation"
			:validation-messages="validationMessages"
			:name="name"
		/>
	</BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";

export default {
	name: 'TextEditorCard',
	components: {
		BaseCard,
	},
	props: {
		title: {
			type: String,
			default: ''
		},
		value: {
			type: String,
			default: ''
		},
		specificData: {
			type: Object,
			default: () => {}
		},
        updateKey: {
            type: Number,
            default: Date.now(),
        },
		validation: {
			type: String,
			default: 'required'
		},
		errorBehavior: {
			type: String,
			default: 'blur'
		},
		validationMessages: {
			type: Object,
			default: () => {}
		},
		name: {
			type: String,
			default: 'richtext'
		}
	},
	computed: {
	baseCardPadding() {
		if(this.title?.length) return '0 25px 25px'

		return '25px'
	}	
	},
	methods: {
		textEditorChanged(value) {
			this.$emit( 'changed', { value, ...this.specificData } );
		},
	},
}
</script>

<style lang='scss' scoped>

.text-editor-card {

	.title {
		font-family: 'Oswald', sans-serif;
		height: 50px;
		line-height: 50px;
		border-bottom: 1px solid $light_gray;
		margin: 0 -25px 25px;
		padding-left: 25px;
		padding-right: 25px;
	}
}

::v-deep {
	.editor__content .ProseMirror {
		.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
			font-family: "Oswald", sans-serif;
		}
	}
	.editor__content .ProseMirror:last-child {
		margin-bottom: 0;
	}
}

</style>