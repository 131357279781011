<template>
  <div class="archive-card">
    <hr />
    <BaseCard padding="30px">
      <div class="archive-card-text">{{ titleText }}</div>
      <FormulateForm
        id="archive-form"
        v-model="form"
        @submit="submitData"
        class="archive-form__body"
      >
        <div class="form">
          <div class="form-input">
            <FormulateInput
              type="text"
              name="title"
              error-behavior="blur"
              validation="required"
              :placeholder="labels.enter_the_name"
            />
          </div>
          <div class="button-wrapper" @click="submitData">
            <Button
              :label="labels.archive"
              variant="btn-purple"
              :styles="{ padding: '9px 15px', fontWeight: 700 }"
              class="ml-4"
            />
          </div>
        </div>
      </FormulateForm>
    </BaseCard>
    <div v-if="error" class="archive-card-error">
      {{ labels.archiveNoMatch }}
    </div>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button";
import { mapActions } from "vuex";
import BaseCard from "@/components/Cards/BaseCard";

export default {
  name: "AdvancedCard",
  data() {
    return {
      form: {},
      error: false,
    };
  },
  components: {
    Button,
    BaseCard,
  },
  props: {
    labels: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    fn: {
      type: Function,
    },
    type: {
      type: String,
    },
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    submitData() {
      if (
        this.title &&
        this.form.title &&
        this.title.toString().toLowerCase() ==
          this.form.title.toString().toLowerCase()
      ) {
        this.$emit("archive");
      } else {
        this.addNotification({
          variant: "danger",
          msg: this.labels.incorrect_name,
          labels: "res.error",
        });
      }
    },
  },
  computed: {
    // submitBtnDisabled has been used for imput but never defined, just made it to return true to avoid error in console
    submitBtnDisabled() {
      return true;
    },
    titleText() {
      return `${this.labels.archive} ${this.type.toLowerCase()}: ${this.title}`;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .formulate-input-element--text {
    width: 100%;
    max-width: 100%;
  }
  .formulate-input .formulate-input-label,
  .archive-card-text {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid $lightgray;
  }
}
.form {
  &-input {
    width: 100%;
  }
  display: flex;
  align-items: flex-start;
}
.button-wrapper {
  display: flex;
  align-items: flex-end;
  padding-bottom: 1px;
}
</style>
